<template>
  <div>
    <b-form-group
      label="Texto"
      label-for="input-text"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-textarea
        id="input-text"
        v-model="$v.likert_option.text.$model"
        :state="validateState('text')"
        aria-describedby="input-text-feedback"
        size="sm"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-text-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Nombre corto"
      label-for="input-short-name"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-short-name"
        v-model="$v.likert_option.short_name.$model"
        :state="validateState('short_name')"
        aria-describedby="input-short-name-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-short-name-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Puntaje"
      label-for="input-score"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-score"
        type="number"
        v-model="$v.likert_option.score.$model"
        :state="validateState('score')"
        aria-describedby="input-score-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-score-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(likert_option.id)"
        class="col"
        style="text-align: left"
      >
        <b-button class="mr-1" size="sm" variant="danger" @click="askForDelete"
          >Eliminar</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "LikertOptionForm",
  mixins: [validationMixin],
  props: {
    LikertOption: {
      type: Object,
    },
    likert_scale_id: {
      type: Number,
      required: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      likert_option: {
        id: this.LikertOption ? this.LikertOption.id : generateUniqueId(),
        likert_scale: this.LikertOption
          ? this.LikertOption.likert_scale
          : this.likert_scale_id,
        text: this.LikertOption ? this.LikertOption.text : "",
        short_name: this.LikertOption ? this.LikertOption.short_name : "",
        score: this.LikertOption ? this.LikertOption.score : 0,
      },
    };
  },
  validations() {
    return {
      likert_option: {
        text: { required },
        short_name: { required },
        score: { required },
      },
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.likert_option[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.likert_option.$touch();
      if (this.$v.likert_option.$anyError) {
        return;
      }
      if (isNaN(this.likert_option.id)) this.create();
      else this.update();
    },
    create() {
      this.$restful
        .Post("/cideu/likert-option/", this.likert_option)
        .then((response) => {
          this.likert_option = response;
          this.$emit("created", response);
          toast("Opción creada.");
        });
    },
    update() {
      this.$restful
        .Put(
          `/cideu/likert-option/${this.likert_option.id}/`,
          this.likert_option
        )
        .then((response) => {
          this.likert_option = response;
          this.$emit("updated", response);
          toast("Opción actualizada.");
        });
    },
    askForDelete() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el Acta?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.delete();
        }
      });
    },
    delete() {
      this.$restful
        .Delete(`/cideu/likert-option/${this.likert_option.id}/`)
        .then(() => {
          this.$emit("deleted", this.likert_option);
          toast("Opción eliminada.");
        });
    },
  },
};
</script>

<style>
</style>